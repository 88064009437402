
@import "../../variables.less";
@import "../../resource/common.less";

@currentWidth: 600px;
.elevator {
	width: 50%;
	padding: @middleSpace;
	display: inline-block;
	height: 77px;

	.elevatorMain {
		text-align: center;
		float: left;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: @fontSizeB;
		height: 100%;
		border: 1px solid;
		border-radius: 6px;
		width: 80px;
		flex-direction: column;
	}

	.eleCode {
		flex-grow: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
	}

	.eleNumber {
		font-size: 14px;
		width: 100%;
	}

	.detail {
		margin-left: 100px;
	}
}

.elevatorContent {
	height: 100%;
	flex-grow: 1;
	display: block;
	overflow: auto;
}

.legends {
	position: absolute;
	width: 200px + 2 * @middleSpace;
	bottom: 20px;
	right: 620px;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	color: #FFF;
	padding: @middleSpace;

	&:empty {
		display: none;
	}

	.legend {
		width: 100px;

		code {
			margin-right: @smallSpace;
		}
	}
}
